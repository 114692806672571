/*
 * @Date: 2022-05-27 11:22:34
 * @LastEditors: wfj
 * @LastEditTime: 2023-10
 * @FilePath: /dc-pay-front/src/store/index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user'
import cart from './modules/cart'
import payment from './modules/payment'
import tool from './modules/tool'
import product from './modules/product'
import favorite from './modules/favorite'
import sys from './modules/sys'
import root from './modules/root'
import { getVersionByParseUrl, getSessionVuexData } from '@/utils/business.js'
import { encryption } from '@/utils/cipher.js'

const { versionName } = getVersionByParseUrl()

Vue.use(Vuex)
const instance = new Vuex.Store({
  ...root,
  modules: {
    user,
    cart,
    tool,
    payment,
    product,
    favorite,
    sys
  },
  plugins: [
    /** 根据游戏版本（wsy-tw）储存和获取，避免出现串数据问题 */
    createPersistedState({
      storage: {
        removeItem: () => {},
        getItem: (key) => {
          // 以游戏版本分块获取
          return getSessionVuexData(key)
        },
        setItem: (key, value) => {
          if (key === 'vuex' && value) {
            value = encryption.encryptByAES(value)
          }
          return sessionStorage.setItem(key, value)
        }
      },
      reducer (state) {
        return {
          // 以游戏版本分块储存
          [versionName]: {
            /** 📢 注意：尽量减少属性缓存，避免过度占用内存 */
            // 需缓存模块 （tool无需缓存，有存组件场景，缓存会异常）
            user: state.user,
            product: state.product,
            payment: state.payment,
            cart: {
              cartLength: state.cart.cartLength,
              cartInfo: state.cart.cartInfo
            },
            tool: {
              trace_id: state.tool.trace_id
            }
          }
        }
      }
    })
  ]
})
Vue.prototype._store = instance

export default instance
