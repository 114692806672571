/*
 * @Date: 2023-10
 * @LastEditors: wfj
 * @LastEditTime: 2023-10
 * @Description:
 */
import { isWithoutShoppingCartMode } from '@/utils/business/sys.js'

const state = {}
const getters = {
  withoutShoppingCartMode () {
    return isWithoutShoppingCartMode()
  }
}
const mutations = {}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
