/*
 * @Date: 2022-05-30 14:03:01
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-08-15 14:34:23
 * @FilePath: /dc-pay-front/src/store/modules/tool.js
 */
import { CacheUserForm, useRenderOverlay } from '@/utils/business.js'
import { getUUID, getVueVm, disposeStatusCode, handleTrack } from '@/utils'
import Vue from 'vue'
import { Toast } from 'vant'
import { $t } from '@/i18n'
import { version } from '@/../package.json'
import { Dialog } from '@/components/ui/Dialog'

const { renderOverlay, destroyOverlay } = useRenderOverlay()
const state = {
  // 需要轮询执行的函数集
  fnMap: [],
  // 判断当前是否是首页
  isHome: true,
  trace_id: getUUID(),
  loadingInstanceList: [], // toast 实例
  // 全屏 toast 实例
  fullLoadingIns: null
}
const getters = {}
const mutations = {
  UPDATE_TOOL_STATE (state, { key, value }) {
    state[key] = value
  }
}
const actions = {
  /**
   * @description: 提示弹窗
   * @param {*} title 标题
   * @param {*} desc 内容
   */
  showDialog (
    { state },
    {
      title = '',
      desc,
      confirmButtonText = $t('common.confirm'),
      cancelButtonText = $t('common.cancel'),
      showCancelButton = false,
      showCloseIcon = false,
      tipConfirmCallbak = () => {},
      cancelCallback = () => {}
    }
  ) {
    const descHtml =
      '<div style="color:#7881AB;font-size: 0.24rem;margin-top:.28rem;">' +
      desc +
      '</div>'
    const line = '\n'
    const message = title + line + descHtml

    Dialog({
      /**
       * @param action: confirm,cancel
       */
      beforeClose: async (action, done) => {
        const callback =
          action === 'confirm' ? tipConfirmCallbak : cancelCallback
        await callback()
        done()
      },
      showCancelButton,
      message,
      showCloseIcon,
      confirmButtonText,
      cancelButtonText
    })
  },
  /**
   * @description: 显示提示设置密码弹窗
   * @param {obj} options  {
   *    ...showDialog方法的配置,
   *    touch_type 触发契机：'firstLogin' | 'useRedeemCode' | 'activity' | 'receiveCoupon' | 'mustSetPwd'
   * }
   */
  showTipPwdDialog ({ dispatch }, options = {}) {
    const defaultOption = {
      desc: $t('pwd.setPwdForSafeTip'),
      confirmButtonText: $t('pwd.toSetBtn'),
      cancelButtonText: $t('pwd.noSetNowBtn'),
      showCancelButton: true
    }
    const { touch_type } = options
    const finallyOption = Object.assign(defaultOption, options)
    const { tipConfirmCallbak, cancelCallback } = finallyOption

    delete options.touch_type
    // 拦截按钮回调，添加埋点
    finallyOption.tipConfirmCallbak = () => {
      handleTrack('click_to_set_pwd', { touch_type })
      tipConfirmCallbak && tipConfirmCallbak()
    }
    finallyOption.cancelCallback = () => {
      handleTrack('click_not_set_pwd', { touch_type })
      cancelCallback && cancelCallback()
    }

    handleTrack('view_dialog_for_tip_pwd', { touch_type })
    dispatch('showDialog', finallyOption)
  },
  showLoading ({ commit, state }, opts = {}) {
    const instance = Toast.loading({
      forbidClick: true,
      ...opts
    })
    const { loadingInstanceList } = state
    commit('UPDATE_TOOL_STATE', {
      key: 'loadingInstanceList',
      value: loadingInstanceList.concat([instance])
    })
  },
  closeLoading ({ commit, state }) {
    const { loadingInstanceList } = state
    const firstInstance = loadingInstanceList.pop()
    commit('UPDATE_TOOL_STATE', {
      key: 'loadingInstanceList',
      value: loadingInstanceList
    })

    if (firstInstance) {
      firstInstance.clear()
    }
  },
  closeAllLoading ({ commit, state }) {
    const { loadingInstanceList } = state
    loadingInstanceList.forEach((item) => item.clear())
    commit('UPDATE_TOOL_STATE', {
      key: 'loadingInstanceList',
      value: []
    })
  },
  visibleFullLoading ({ commit, state }, visible = true, opts = {}) {
    if (visible) {
      renderOverlay()
      const instance = Toast.loading({
        forbidClick: true,
        // overlay: true,
        ...opts
      })
      commit('UPDATE_TOOL_STATE', { key: 'fullLoadingIns', value: instance })
    } else {
      destroyOverlay()
      if (state.fullLoadingIns) {
        state.fullLoadingIns.clear()
        commit('UPDATE_TOOL_STATE', { key: 'fullLoadingIns', value: null })
      }
    }
  },
  // 初始化tool的数据
  initData ({ commit }) {},

  // 返回首页
  goHome ({ state }, data = { isReplace: false }) {
    const vm = getVueVm()
    const { path } = vm.$route
    if (path === '/') return

    if (data.isReplace) {
      vm.$router.replace('/')
    } else {
      vm.$router.push('/')
    }
  },
  sendTrack (
    { state, rootState },
    { eventName, event_data = {}, base_data = {} }
  ) {
    const vizier = Vue.prototype.vizier
    const { origin_role_id } = rootState.user.userInfo
    const { server_id } = rootState.user.loginForm
    const { logical_region_id, user_id: show_id } =
      CacheUserForm.getLocalUserForm() || {}
    const { trace_id } = state
    const { status_code } = event_data

    if (status_code) {
      // 将接口的前端异常转换为number
      event_data.status_code = disposeStatusCode(status_code)
    }
    const defaultEventData = { trace_id }
    const defaultBaseData = {
      // 页面编号（目前仅一个路由）
      page_seq: 1,
      stay_seconds: 0,
      logical_region_id
    }

    defaultBaseData.personal_id = origin_role_id || ''
    defaultEventData.show_id = show_id || ''
    defaultEventData.server_id = server_id || ''
    defaultEventData.web_version = version

    // 合并配置
    const mergeEventData = Object.assign({}, defaultEventData, event_data)
    const mergeBaseData = Object.assign({}, defaultBaseData, base_data)

    vizier.set_base_data(mergeBaseData)
    vizier.track_event(eventName, mergeEventData)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
