/*
 * @Author: wfj
 * @Date: 2021-04-07 14:01:18
 * @LastEditors: wfj
 * @LastEditTime: 2023-07
 * @FilePath: /dc-pay-front/src/utils/track.js
 */
// 埋点
import Vue from 'vue'
import { CacheUserForm, getCurrGameConfigProperty } from '@/utils/business.js'
import { initTrack } from './trackHelper'

const { logical_region_id } = CacheUserForm.getLocalUserForm() || {}
const instance = initTrack(logical_region_id, getCurrGameConfigProperty('title'))

Vue.prototype.vizier = instance
