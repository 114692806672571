/*
 * @Date: 2022-11
 * @LastEditors: wfj
 * @LastEditTime: 2022-11
 * @Description:
 */

import Vue from 'vue'
// 注册全局指令
Vue.directive('oneline', {
  bind (el, obj) {
    el.style.overflow = 'hidden'
    el.style.whiteSpace = 'nowrap'
    el.style.textOverflow = 'ellipsis'
    if (obj.value) {
      el.style.width = obj.value
    }
  }
})

const formatPrice = (price, el) => {
  if (price) {
    el.innerHTML = String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return el.innerHTML
}

/**
 * @description: 为金额增加加，分割符
 * @use: <p v-priceFormat>10000</p>
 */
Vue.directive('priceFormat', {
  bind (el, obj) {
    formatPrice(obj.value, el)
  },
  inserted () {},
  update (el, obj) {
    formatPrice(obj.value, el)
  },
  componentUpdated () {},
  unbind () {}
})
