/*
 * @Date: 2022-08-24 14:04:43
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-08-24 14:14:59
 * @FilePath: /dc-pay-front/src/store/modules/favorite.js
 */
const state = {}
const getters = {}
const mutations = {}
const actions = {
  async getFavoriteList ({ state, rootState }) {
    const { role_id } = rootState.user.userInfo
    return await Apis.getFavorites({ role_id })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
