<!--
 * @Date: 2021-12-10 10:46:18
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-02-07 11:14:57
 * @FilePath: /dc-pay-front/src/App.vue
-->
<!--
 *                   江城子 . 程序员之歌
 *
 *               十年生死两茫茫，写程序，到天亮。
 *                   千行代码，Bug何处藏。
 *               纵使上线又怎样，朝令改，夕断肠。
 *
 *               领导每天新想法，天天改，日日忙。
 *                   相顾无言，惟有泪千行。
 *               每晚灯火阑珊处，夜难寐，加班狂。
 *
 -->

<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 -->
<template>
  <div
    id="app"
    :class="[
      { 'blank-content': isPureDomainName },
      { existNavbarHeight: showNavBar },
    ]"
  >
    <template>
      <!-- 不存在pathname时，仅展示空白页（pathname 用于区分游戏及语言，如：/wsy-tw/） -->
      <div v-if="isPureDomainName"></div>
      <router-view v-else />

      <!-- 顶部导航栏 -->
      <NavBar
        v-if="showNavBar"
        :left-arrow="showNavBarLeftArrow"
        :title="navBarTitle"
      ></NavBar>

      <!-- 购物车入口 -->
      <CartEntrance
        v-if="showCartEntry"
        class="common-cart-entry-fixed common-cart-entry-bg"
      />

      <!-- 该页面位置 public/redirect.html，用于将页面在支付后中转至对应支付页（该iframe不做展示） -->
      <iframe
        ref="iframeRef"
        :src="handleRedirectIframe"
        frameborder="0"
        width="0"
        height="0"
        v-if="!!handleRedirectIframe"
        v-show="false"
        @load="iframeLoaded"
      ></iframe>
    </template>

    <!-- 活动弹窗，需放置在App.vue 中，否则切换路由后续重新挂载 -->
    <ActPopup></ActPopup>
  </div>
</template>

<script>
import {
  getVersionByParseUrl,
  getCurrGameConfigProperty
} from '@/utils/business.js'
import { useOnlyPaymentPageMode } from '@/hooks/useShoppingMode'
import { urlConfig, pageConfig } from '@/utils/globalData.js'
import NavBar from '@/components/NavBar.vue'
import CartEntrance from '@/components/CartEntrance.vue'
import ActPopup from '@/views/Activity/popup/ActPopup.vue'
import { mapState, mapGetters } from 'vuex'
import { startRegisterQiankun } from '@/qiankun'
import { listenerVersionUpdate } from '@/utils/business/version.js'

export default {
  components: { NavBar, CartEntrance, ActPopup },
  provide () {
    return {
      versionInfo: getVersionByParseUrl()
    }
  },
  data () {
    return {
      handleRedirectIframe: ''
    }
  },
  computed: {
    ...mapState('tool', ['isHome']),
    ...mapGetters('sys', ['withoutShoppingCartMode']),

    showNavBarLeftArrow () {
      const unshowList = ['/resetPassword']
      const { path } = this.$route
      return !unshowList.includes(path) && !this.isOnlyPaymentPageMode()
    },
    navBarTitle () {
      return this.$route?.meta?.title || ''
    },
    showCartEntry () {
      // 显示购物车入口的页面
      const showCartEntryRouter = ['/favorite']
      const { path } = this.$route
      return (
        !this.withoutShoppingCartMode && showCartEntryRouter.includes(path)
      )
    },
    // 控制顶部导航栏显示
    showNavBar () {
      const { hideNavBarRouters } = pageConfig
      const { path } = this.$route
      return !this.isHome && !hideNavBarRouters.includes(path)
    },
    // 是否是纯域名（无路由）
    isPureDomainName () {
      const { pathname } = location
      return !pathname || pathname === '/'
    }
  },
  methods: {
    iframeLoaded () {
      const { homeUrl } = urlConfig.redirect
      const receiver = this.$refs.iframeRef.contentWindow

      receiver.postMessage(homeUrl, '*')
    },
    initData () {
      const { redirectUrl } = urlConfig.redirect

      this.$store.dispatch('tool/initData')
      this.$store.dispatch('product/initData')

      // 性能优化（避免和首屏抢请求数）
      setTimeout(() => {
        this.handleRedirectIframe = `${redirectUrl}?pageType=saveSession`
      }, 5000)
    }
  },
  created () {
    document.title = getCurrGameConfigProperty('title')
    this.initData()
  },
  mounted () {
    // 注册刷新token计时器
    this.$store.dispatch('user/refreshTokenCountdown')
    // 注册qiankun（会将router传递给子应用，需在挂载app结束才能调用）
    startRegisterQiankun()
    // 监听项目版本变化
    listenerVersionUpdate()
  },
  setup () {
    const { isOnlyPaymentPageMode } = useOnlyPaymentPageMode()
    return { isOnlyPaymentPageMode }
  }
}
</script>

<style scoped lang="less">
@import url("~@/styles/theme.less");
@import url("~@/styles/common.less");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dp-text-primary);
  font-size: var(--dp-text-md);
  line-height: 1.32;
  background-color: var(--dp-bg-primary);
  min-height: 100%;
  position: relative;
  z-index: 1;
  &.blank-content {
    background-color: var(--dp-bg-primary);
  }
  &.existNavbarHeight {
    min-height: calc(100% - @navbarHeight);
  }
}

#app * {
  font-size: var(--dp-text-md);
}

@media screen and (min-width: 750px) {
  #app {
    width: 750px;
    margin: 0 auto;
    .foot-bar {
      width: 750px;
      left: 50%;
      margin-left: -375px;
    }
  }
}

// 覆盖vant的toast组件样式
.van-toast--loading {
  background-color: transparent;
  .van-toast__loading {
    color: @darkFontColor;
  }
}
</style>
