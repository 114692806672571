/*
 * @Date: 2022-05-30 14:03:01
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2025-03-13 11:22:24
 * @FilePath: /dc-pay-front/src/store/modules/product.js
 */
import { Dialog } from '@/components/ui/Dialog'
import { $t } from '@/i18n'
import {
  validateUnpayableProduct,
  PRODUCT_CONFIG,
  useHandleMenu,
  isLimitNum
} from '@/utils/business/product.js'
import { cloneDeep } from 'lodash-es'

const { addOtherSubType } = useHandleMenu()

const state = {
  // 商品分类
  types: [],
  // 商品标签
  labels: [],
  // 当前选中 item 的索引
  activeKey: -1,
  /**
   * 互斥组信息 mutexInfo: { mutexIds: [], mutexGroup: {} }
   * mutexGroup { [mutex_id]: { purchased: 已购买数量, limit: 互斥购买数量上限 } }
   */
  mutexInfo: {}
}
const getters = {
  // 合并类型、标签列表 return [{ label_id, label_name, image_url, isType, isLabel }]
  mergeList (state) {
    const { types, labels } = state
    const { staticMenuList } = PRODUCT_CONFIG
    const transform = (list, otherProp = {}) =>
      list.map((item) => {
        const {
          label_id,
          label_name,
          type_id,
          type_name,
          image_url = '',
          sub_type_list = []
        } = item
        const data = {
          label_id,
          label_name,
          image_url
        }
        // 类型特有属性
        if (type_id) {
          data.label_id = type_id
          data.label_name = type_name
          data.sub_type_list = sub_type_list
        }

        return Object.assign(data, otherProp)
      })
    const typeList = transform(types, { isType: true })
    const labelList = transform(labels, { isLabel: true })
    return [...staticMenuList, ...labelList, ...typeList]
  },
  /**
   * @description: 互斥组信息
   * @return { [mutex_id]: { purchased: 已够数量, purchasable: 可购买数量, limit, cartCount: 已加购数量, isMaximal: 是否达到互斥组购买上限 } }
   */
  mutexGroup (state, getters, rootState) {
    const mutexGroup = cloneDeep(state.mutexInfo.mutexGroup)
    const { cartMutexCount } = rootState.cart
    for (const key in mutexGroup) {
      const item = mutexGroup[key]
      const isLimit = isLimitNum(item)
      item.cartCount = cartMutexCount[key] || 0

      const { purchased, cartCount, limit } = item
      item.isMaximal = isLimit && purchased + cartCount >= limit
      if (isLimit) {
        item.purchasable = limit - purchased
      }
    }
    return mutexGroup
  }
}
const mutations = {
  UPDATE_PRODUCT_STATE (state, data) {
    for (const key in data) {
      state[key] = data[key]
    }
  }
}
const actions = {
  // 计算、存储互斥商品信息
  setMutexGroupInfo ({ commit, rootState }, products) {
    const defaultMutexInfo = { mutexIds: [], mutexGroup: {} }
    const mutexGroupItem = { purchased: 0, limit: 0 }

    // 过滤数据
    const mutexInfo = products.reduce((result, product) => {
      const { mutex_id, purchased, limit } = product
      const { mutexGroup } = result
      if (!mutex_id) return result

      if (!mutexGroup[mutex_id]) {
        mutexGroup[mutex_id] = cloneDeep(mutexGroupItem)
        mutexGroup[mutex_id].limit = limit
      }

      result.mutexIds.push(mutex_id)
      mutexGroup[mutex_id].purchased += purchased
      return result
    }, defaultMutexInfo)
    // 去重
    mutexInfo.mutexIds = Array.from(new Set(mutexInfo.mutexIds))

    commit('UPDATE_PRODUCT_STATE', { mutexInfo })
  },
  /**
   * 显示限购提示弹窗
   * @param {object}
    {
      timeoutNames: ['购买项名称1']
      excessInfoList: [{ name: 商品名称, payableNum: 可购买数量 }]
      tipConfirmCallbak: 点击确认回调
    }
   * @use
        this.$store.dispatch('product/showLimitTipDialog', { timeoutNames: ['11'], excessInfoList:[{ name: '商品名称', payableNum: 2 }] })
   */
  showLimitTipDialog (
    { state },
    { timeoutNames = [], excessInfoList = [], tipConfirmCallbak = () => {} }
  ) {
    const limitTimeNames = timeoutNames.join('、')
    let resultMsg = ''
    const disposeMsgFn = (productName, lastLineText) => {
      if (productName) {
        // 不同类型礼包以换行分割
        const insertBefortResultMsg = resultMsg
          ? '<div style="margin-top:.36rem;"></div>'
          : ''
        // 礼包名称高亮
        lastLineText =
          '<div style="color:#7881AB;font-size: 0.24rem;">' +
          lastLineText +
          '</div>'
        resultMsg += `${insertBefortResultMsg}${productName}${lastLineText}`
      }
    }

    // 已过期无法购买
    disposeMsgFn(limitTimeNames || '', $t('cart.overdueTip'))
    excessInfoList.forEach((item) => {
      // 最多只能购买${item.payableNum}个
      disposeMsgFn(
        item.name || '',
        $t('cart.maxPurchasable', { num: item.payableNum })
      )
    })

    Dialog({
      message: resultMsg,
      confirmButtonText: $t('cart.sure')
    }).then(tipConfirmCallbak)
  },
  async getTypes ({ commit, state }) {
    const { product_type_list: types } = await Apis.getClassify()
    // 为每项添加【其他】子类
    addOtherSubType(types)
    commit('UPDATE_PRODUCT_STATE', { types })
    return state.types
  },
  async getLabels ({ commit, state }) {
    const { product_label_list: labels } = await Apis.getLabels()
    commit('UPDATE_PRODUCT_STATE', { labels })

    return state.labels
  },
  /**
   * @description: 根据id获取购买项信息
   * @param {object} { country: 可选，地区编号, product_cart: [{count,product_id}], extend: 额外扩展字段 }
   */
  async getProductByIds ({ rootState }, { country, product_cart, extend = {} }) {
    const { role_id, user_id } = rootState.user.userInfo
    const { server_id } = rootState.user.loginForm
    // 默认国家
    const { country_en_us } = rootState.payment.defaultCountryInfo
    country = country || country_en_us || ''
    const { product_allowed } = await Apis.getGoodsMoreInfo({
      role_id,
      user_id,
      server_id,
      country,
      product_cart,
      extend: JSON.stringify(extend)
    })
    return product_allowed
  },
  /**
   * @description: 从服务器校验商品是否超过限时、限购
   * @param {arr} list 购买项列表
   * @return {
      timeoutList, // 超时列表
      excessList, // 超过可购买数量列表
      payableList, // 可购买列表
      existUnpayable, // {boolean} 是否存在不可支付项
      timeoutNames, // 超时的购买项名称集合 ['商品1', '商品2']
      excessInfoList // // 超量的购买项名称集合 [{ name, product_id, payableNum }]
    }
   */
  async validateLimit ({ dispatch }, { list }) {
    const { timeoutList, excessList, payableList } =
      validateUnpayableProduct(list)
    const totalLen = timeoutList.length + excessList.length
    const timeoutNames = timeoutList.map((item) => item.name)
    const excessInfoList = excessList.map((item) => {
      const { name, limit, purchased, id: product_id } = item
      // 可购买数量
      let payableNum = limit - purchased
      payableNum = payableNum < 0 ? 0 : payableNum
      return { name, payableNum, product_id }
    })

    return {
      timeoutList,
      excessList,
      payableList,
      existUnpayable: totalLen > 0,
      timeoutNames,
      excessInfoList
    }
  },
  /**
   * @description: 校验商品限时、限购并提示
   * @param {obj} {
   *    list: 购买项列表,
   *    tipConfirmCallbak: 提示弹窗确认的回调 ({ payableList })=>{},
   * }
   * @return {
      existUnpayable, // {boolean} 是否存在不可支付项
      payableList, // 可购买列表
    }
   */
  async validateProductAndTip ({ dispatch }, { list, tipConfirmCallbak }) {
    const { existUnpayable, timeoutNames, payableList, excessInfoList } =
      await dispatch('validateLimit', { list })
    if (existUnpayable) {
      dispatch('showLimitTipDialog', {
        timeoutNames,
        excessInfoList,
        tipConfirmCallbak: () => {
          tipConfirmCallbak({ payableList, excessInfoList })
        }
      })
    }
    return { existUnpayable, payableList }
  },
  // 初始化数据
  initData ({ commit }) {}
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
