<!--
 * @Date: 2023-02
 * @LastEditors: wfj
 * @LastEditTime: 2023-06
 * @Description: 活动弹窗
-->
<template>
  <van-popup getContainer="body" v-model="showDialog" :lazy-render="false" :closeable="false">
    <div class="act-container">
      <div :id="STATIC_STATE.manualMountElId"></div>
    </div>
  </van-popup>
</template>

<script setup>
import { usePopup } from '@/views/Activity/popup/ActPopup.js'
import { STATIC_STATE } from '@/qiankun/actBusiness/common.js'

const { showDialog } = usePopup()
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .act-container {
    width: 750px;
    margin: 0 auto;
  }
}
</style>
