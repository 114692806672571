/*
 * @Date: 2022-06-10 14:53:15
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2025-03-11 11:07:36
 * @FilePath: /dc-pay-front/src/store/modules/cart.js
 */
import {
  validateDisabledProduct,
  splitDisabledArr,
  countAmountInfo,
  filterPropByProducts,
  parseFloatNum
} from '@/utils/business/product.js'
import { shoppingCartConfig } from '@/utils/globalData'

const state = {
  // 购物车列表
  cartList: [],
  // 超时、超购列表
  disabledCartList: [],
  cartInfo: {
    total_amount_free_estimate: 0, // 券可抵扣金额
    cartTotalAmount: 0, // 总商品金额（含失效）
    currency: '' // 货币单位
  },
  cartLength: 0, // 总商品数量
  cartMutexCount: {} // 各互斥组商品加购数量，例如 { [mutex_id]: number }
}
const getters = {
  /**
   * @description: 所有商品合计金额（含失效商品）
   * @return {string} 1000 HKD
   */
  viewTotalAmount (state) {
    const { cartTotalAmount, currency, total_amount_free_estimate } =
      state.cartInfo
    if (!cartTotalAmount) return ''
    const viewPrice = parseFloatNum(
      cartTotalAmount - total_amount_free_estimate
    )
    return `${viewPrice} ${currency}`
  },
  // 已勾选购物车记录
  selectedCartList (state) {
    return state.cartList.filter((item) => item.selected)
  },
  /**
   * 已勾选商品的合计金额、币种
   * @returns { amount, currency, count }
   */
  amountTotalInfo (state, getters) {
    const { selectedCartList } = getters
    // 统计金额
    return countAmountInfo(selectedCartList)
  },
  // 剩余可加购数量
  canAddCartNum (state) {
    return shoppingCartConfig.maxLength - state.cartLength
  },
  /**
   * @description: 商品map
   * @return {obj} {[id]: productItem}
   */
  productMap (state) {
    return state.cartList.reduce((map, i) => {
      map[i.id] = i
      return map
    }, {})
  }
}
const mutations = {
  // 修改所有商品的勾选状态
  SET_CART_LIST_SELECTED (state, isSelected) {
    state.cartList.forEach((item) => (item.selected = isSelected))
  },
  UPDATE_CART_STATE (state, data) {
    for (const key in data) {
      state[key] = data[key]
    }
  }
}
const actions = {
  // 更新购物车列表
  async getCartList ({ rootState, commit }) {
    const { server_id } = rootState.user.loginForm
    const { cartInfo } = rootState.cart
    const { user_id, role_id } = rootState.user.userInfo
    const { country_en_us: country } = rootState.payment.defaultCountryInfo
    const cartData = await Apis.getShoppingCart({
      role_id,
      user_id,
      server_id,
      country
    })
    const { product_cart, mutex_product = [] } = cartData

    product_cart.forEach((item) => {
      item.disabled = validateDisabledProduct(item)

      // 为列表添加 mutex_id 字段
      const mutexItem = mutex_product.find(
        ({ product_id }) => item.id === product_id
      )
      mutexItem && (item.mutex_id = mutexItem.mutex_id)
    })
    const { useabledList, disabledList } = splitDisabledArr(product_cart)
    // 购物车默认全选
    useabledList.forEach((item) => (item.selected = true))
    // 统计所有商品金额
    const { amount, currency, count } = countAmountInfo(product_cart)

    const updateMap = {
      cartList: useabledList,
      disabledCartList: disabledList,
      cartLength: count,
      cartInfo: Object.assign(cartInfo, {
        cartTotalAmount: amount,
        currency: currency
      })
    }
    commit('UPDATE_CART_STATE', updateMap)
    Object.assign(cartData, { product_cart })
    return cartData
  },
  /**
   * @description: 生成购物车订单（传商品列表）
   * @param {*} products 要购买的商品
   * @return {promise}
   */
  submitCartByProducts ({ rootState, dispatch }, { products }) {
    const product_cart = filterPropByProducts(products)
    return dispatch('submitCart', { product_cart })
  },
  /**
   * @description: 生成购物车订单
   * @param {*} product_cart [{ product_id, count:商品数量 }]
   * @return {promise}
   */
  submitCart ({ rootState }, { product_cart }) {
    const { role_id } = rootState.user.userInfo
    return Apis.submitShoppingCart({ role_id, product_cart })
  },
  // 获取购物车长度
  async getCartCount ({ state, rootState, commit }) {
    const { role_id } = rootState.user.userInfo
    const { country_en_us: country } = rootState.payment.defaultCountryInfo
    const { cartInfo } = state
    const {
      count: cartLength,
      total_amount_display: cartTotalAmount = 0,
      currency = '',
      total_amount_free_estimate = 0,
      mutex_count: cartMutexCount
    } = await Apis.getShoppingCartCount({ role_id, country })

    Object.assign(cartInfo, {
      cartTotalAmount,
      currency,
      total_amount_free_estimate
    })
    commit('UPDATE_CART_STATE', { cartLength, cartInfo, cartMutexCount })
    return { cartLength, cartTotalAmount, currency }
  },
  // 为商品设置已加购数量
  setCartAmountToProducts ({ state }, { products, keyName = 'count' }) {
    state.cartList.forEach((cart) => {
      const product = products.find((product) => product.id === cart.id)
      if (product) {
        product[keyName] = cart.count
      }
    })
    return products
  },
  // 更新购物车列表 && 为商品设置已加购数量
  async updateCountByCart ({ dispatch }, products) {
    return dispatch('getCartList').finally(() => {
      dispatch('setCartAmountToProducts', { products })
    })
  },

  /**
   * @description: 批量更新购物车商品
   * 两种模式：1.传 product_cart 为普通添加商品；2.favorite_group_id 为添加收藏夹
   * @param {array} product_cart [{ product_id, count:商品数量，为0则删除 }]
   * @param {string} favorite_group_id 收藏夹id
   * @param {number} mode: 模式1传 0；模式2传 1或-1（ 0 直接更新商品数量；1 组合加入购物车；-1 组合减少购物车）
   * @use 模式1：dispatch('cart/updateCartCount', { product_cart })
   * @use 模式2：dispatch('cart/updateCartCount', { favorite_group_id, mode: 1 })
   * @return {Promise}
   */
  async updateCartCount (
    { rootState, dispatch, commit, state },
    { product_cart = [], mode = 0, favorite_group_id }
  ) {
    const { role_id } = rootState.user.userInfo
    // 需删除商品
    const deleteIds = product_cart
      .filter((item) => item.count === 0)
      .map((item) => item.product_id)
    const data = { role_id, mode }

    if (favorite_group_id) {
      data.favorite_group_id = favorite_group_id
    } else {
      data.product_cart = product_cart
    }

    await Apis.updateShoppingCart(data)
    if (deleteIds?.length) {
      dispatch('deleteCarts', deleteIds)
    }
    await dispatch('getCartCount')
  },
  /**
   * 批量删除购物车记录（仅删除前端记录）
   * @param {id[]} ids 要删除的商品id集合
   */
  deleteCarts ({ commit, state }, ids) {
    const { cartList } = state
    const result = cartList.filter((item) => ids.indexOf(item.id) === -1)
    commit('UPDATE_CART_STATE', { cartList: result })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
