/*
 * @Date: 2022-05-06 14:29:52
 * @LastEditors: wenfujie
 * @LastEditTime: 2022-05-17 19:53:04
 * @FilePath: /dc-pay-front/src/sentry/index.js
 */
// Sentry 集成
import pkg from '../../package.json'
import * as Sentry from '@sentry/vue'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

const MateSentry = {
  install: (Vue, opts) => {
    const {
      router,
      tracingOrigins = [
        'localhost',
        process.env.VUE_APP_SENTRY_TRACING_ORIGIN,
        /^\//
      ]
    } = opts || {}

    Vue.prototype.$sentry = Sentry

    Sentry.init({
      Vue,
      // 项目监控api address
      dsn: `${process.env.VUE_APP_SENTRY_DSN}/${process.env.VUE_APP_SENTRY_PROJECT_ID}`,
      // 发布
      release: `${pkg.name}@${process.env.VUE_APP_DEPLOY_VERSION ||
        'v' + pkg.version}`,
      // 环境, 可自定义支持多环境
      environment: process.env.NODE_ENV,
      // is optional and is true if it is not provided. If you set it to false, Sentry will suppress sending all Vue components' props for logging.
      attachProps: true,
      // is optional and is false if it is not provided. If you set it to true, Sentry will call Vue's original logError function as well.
      logErrors: true,
      // 打开或关闭调试模式。如果启用了调试，则在发送事件出错时，SDK将尝试打印出有用的调试信息。默认值始终为false。通常不建议在生产debug中将其打开，尽管打开模式不会引起任何安全隐患。
      debug: process.env.NODE_ENV === 'development',
      // 初始范围数据设置, 通常可用于设置应用、用户的初始数据
      initialScope: {
        tags: { tag: process.env.VUE_APP_DEPLOY_VERSION || 'v' + pkg.version }
        // userName: { id: 42, email: 'xxx@xxx.com' }
      },
      // ---性能追踪---
      // 错误异常采样率
      tracesSampleRate: 1.0,
      // 跟踪子组件，并查看有关渲染过程的更多详细信息
      trackComponents: true,
      // hook 默认为 ['mounted']
      hooks: ['created', 'mounted'],
      // 集成配置
      integrations: [
        // Vue Router 路由集成
        // 跟踪程序加载期间的性能
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins
        }),
        // 控制台日志捕获
        new CaptureConsoleIntegration({
          // array of methods that should be captured
          // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
          levels: ['warn', 'error']
        })
      ]
    })
  }
}

export default MateSentry
