/*
 * @Date: 2022-10
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-08-15 11:12:59
 * @Description:
 */
import { Toast } from 'vant'
import { $t } from '@/i18n'

const state = {}
const getters = {}
const mutations = {}
const actions = {
  // 校验用户是否存在密码，不存在则显示【提醒设置密码弹窗】
  async ifShowPwdTip ({ dispatch }, { touch_type }) {
    const existPwd = await dispatch('user/cacheCheckExistPwd')
    if (!existPwd) {
      dispatch('tool/showTipPwdDialog', {
        touch_type,
        tipConfirmCallbak: async () => {
          await dispatch('sendResetMailCommon')
        },
        cancelCallback: () => {}
      })
    }
  },
  async sendResetMailCommon ({ dispatch }, options = {}) {
    const finallyOpt = Object.assign(
      { sucTip: $t('pwd.sendEmailSucTip'), duration: 2000 },
      options
    )
    const { sucTip: message, duration } = finallyOpt

    await dispatch('tool/showLoading')
    await dispatch('user/sendResetMail').finally(() =>
      dispatch('tool/closeLoading')
    )
    setTimeout(() => {
      Toast({ message, duration })
    }, 0)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
