/*
 * @Date: 2022-05-27 11:31:51
 * @LastEditors: wfj
 * @LastEditTime: 2023-08
 * @FilePath: /dc-pay-front/src/store/modules/user.js
 */
import { $t } from '@/i18n'
import { Toast } from 'vant'
import User, { isLogin } from '@/utils/business/user.js'
import { CacheUserForm, ifOpenCouponVersion } from '@/utils/business.js'
import { useSaveLocalThirdInfo } from '@/views/User/ThirdPartyLogin'
import { THIRD_PARTY_LOGIN_TYPE } from '@/utils/globalData.js'

const { getLocalLoginType } = useSaveLocalThirdInfo()
const state = {
  // 刷新token计时器
  timer: null,
  // 刷新token的时间(时间戳)
  refreshTokenTime: null,
  // 发送邮件冷却时间
  emailCDTime: 0,
  // 是否存在密码
  existPwd: false,
  // 是否使用密码登入
  usePsdLogin: false,
  // 是否为免输密码token
  is_not_verify_password: false,
  userInfo: {},
  loginForm: {
    user_id: '',
    server_id: '',
    pwd: '',
    // 仅非购物车使用该值
    product_id: [],
    pay_type_id: ''
  },
  // 三方登入信息
  thirdLoginInfo: {
    // 三方id（steam id；fb id）
    third_id: '',
    third_type: getLocalLoginType()
  },
  /**
   * 客户端从地址参数透传过来的key
   * 作用：在创建订单接口中透传，最终给到大数据
   */
  app_key: ''
}

const getters = {
  // 是否是三方登入
  isThirdLogin (state) {
    return !!state.thirdLoginInfo.third_type
  },
  // 判断当前三方登入类型
  validateCurrentLoginType (state) {
    return {
      isSteam: state.thirdLoginInfo.third_type === THIRD_PARTY_LOGIN_TYPE.steam
    }
  },
  /**
   * @description: 判断授权
   * @return {obj} {
   *  usePsdLogin:是否使用密码登录
   *  needAuthOnUserCoupon:使用优惠券需授权（未在使用密码登入时需授权）
   *  needShowPsdTipDialog:是否提示用户设置密码(仅提醒一次，根据服务端字段判断)
   * }
   */
  userAuth (state) {
    const { existPwd, usePsdLogin, userInfo } = state
    const { is_not_set_pwd_remind } = userInfo

    const needAuthOnUserCoupon = !usePsdLogin
    const needShowPsdTipDialog =
      ifOpenCouponVersion() && !existPwd && !is_not_set_pwd_remind

    return {
      usePsdLogin,
      needAuthOnUserCoupon,
      needShowPsdTipDialog,
      existPwd
    }
  }
}

const mutations = {
  /**
   * @description: 更新刷新token的时间
   * @param {*} token_expire token有效时间(ms)
   */
  UPDATE_REFRESH_TIME (state, token_expire) {
    const advance = 61 * 1000
    state.refreshTokenTime = +new Date() + token_expire - advance
  },
  CLEAR_TIMER (state) {
    const { timer } = state
    if (timer) {
      clearTimeout(timer)
      state.timer = null
    }
  },
  UPDATE_STATE (state, data) {
    for (const key in data) {
      state[key] = data[key]
    }
  },
  // 更新对象的部分属性
  UPDATE_OBJECT (state, data) {
    for (const key in data) {
      state[key] = Object.assign(state[key], data[key])
    }
  },
  UPDATE_LOGIN_FORM (state, data) {
    data = Object.assign(state.loginForm, data)
    const { product_id } = data
    // 兼容旧版将 product_id 设置为string
    if (!Array.isArray(product_id)) {
      data.product_id = product_id ? [product_id] : []
    }
    state.loginForm = data
  }
}

const actions = {
  /**
   * @description: 校验密码是否完善
   * @return {Boolean} true为已完善
   */
  async isCompletePwd ({ state, dispatch }) {
    try {
      const existPwd = await dispatch('cacheCheckExistPwd')
      if (!existPwd) {
        Toast($t('pwd.pleaseToSetPwdTip'))
        return false
      }

      const isComplete = await dispatch('isCompleteForm', ['pwd'])
      if (!isComplete) {
        return false
      }
    } catch (error) {
      return false
    }
    return true
  },
  refreshTokenCountdown ({ state, dispatch, commit }) {
    const { refreshTokenTime } = state
    const currTimestamp = +new Date()
    commit('CLEAR_TIMER')

    if (isLogin() && refreshTokenTime && refreshTokenTime > currTimestamp) {
      const interval = refreshTokenTime - currTimestamp
      const newTimer = setTimeout(async () => {
        if (isLogin()) {
          await dispatch('updateToken')
          await dispatch('refreshTokenCountdown')
        }
      }, interval)

      commit('UPDATE_STATE', { timer: newTimer })
    }
  },
  async updateToken ({ state, dispatch, commit }) {
    const user = await dispatch('genUserInstance')
    const { newtoken: token, token_expire } = await user.refreshToken()
    const userInfo = Object.assign(state.userInfo, { token, token_expire })
    commit('UPDATE_STATE', { userInfo: userInfo })
    commit('UPDATE_REFRESH_TIME', token_expire)
  },
  genUserInstance ({ state }) {
    const { server_id, user_id: role_no, pwd } = state.loginForm
    return new User({ server_id, role_no, pwd })
  },
  async tagTipPwdDialog ({ state, dispatch, commit }) {
    const user = await dispatch('genUserInstance')
    await user.tagShowedDialog()
    const { userInfo } = state
    userInfo.is_not_set_pwd_remind = true
    commit('UPDATE_STATE', { userInfo: userInfo })
  },
  // 发送重置密码邮件
  async sendResetMail ({ state, commit, dispatch }) {
    const user = await dispatch('genUserInstance')
    const { emailCDTime } = await user.sendResetMail()

    commit('UPDATE_STATE', { emailCDTime: emailCDTime })
    return { emailCDTime }
  },
  /**
   * @description: 判断是否存在密码（先用vuex变量判断，不存在再调接口）
   * @return {boolean} 是否存在密码
   */
  async cacheCheckExistPwd ({ state, dispatch, getters }) {
    const { existPwd } = getters.userAuth
    !existPwd && (await dispatch('checkExistPwd'))
    return getters.userAuth.existPwd
  },
  /**
   * @description: 请求api判断是否存在密码
   * @return {Promise<obj>} { isPsdAuth, existPwd }
   */
  async checkExistPwd ({ state, commit, dispatch }) {
    const user = await dispatch('genUserInstance')
    const { usePsdLogin, existPwd } = await user.checkPsdConfig()
    commit('UPDATE_STATE', { usePsdLogin, existPwd })
    return { usePsdLogin, existPwd }
  },
  /**
   * @description: 判断表单是否完整
   * @param {arr} keys 要校验的字段，对应form的key，如：['user_id','server_id']
   * @return {boolean} 表单是否填写完整
   */
  isCompleteForm ({ state, rootState }, keys) {
    const mapTip = {
      user_id: $t('form.inputIdTip'),
      server_id: $t('form.selectServerTip'),
      product_id: $t('form.selectProductTip'),
      pay_type_id: $t('form.selectPayMethodTip'),
      pwd: $t('pwd.writePwdLabel')
    }
    const emptyFormTip = keys
      .filter((key) => {
        // 不同key校验策略
        const map = {
          default: () => !state.loginForm[key],
          product_id: () => true
        }
        const fn = map[key] || map.default
        return fn()
      })
      .map((key) => mapTip[key])

    if (emptyFormTip && emptyFormTip.length) {
      Toast(emptyFormTip[0])
      return false
    }
    return true
  },
  async getUserInfo ({ state, commit, dispatch }, data = {}) {
    const { is_not_verify_password = false } = data
    const user = await dispatch('genUserInstance')
    const userInfo = await user.login(data)
    const { token_expire } = userInfo
    commit('UPDATE_STATE', { userInfo, is_not_verify_password })
    commit('UPDATE_REFRESH_TIME', token_expire)
    dispatch('refreshTokenCountdown')
    // 设置用户表单缓存
    const { user_id, pwd, server_id } = state.loginForm
    const localFormData = { user_id, pwd, server_id }
    // 避免覆盖优惠券输入的密码
    if (!pwd) delete localFormData.pwd

    CacheUserForm.setLocalUserForm(localFormData)

    return userInfo
  }
}

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
