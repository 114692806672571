import "vant/es/image/style";
import _Image from "vant/es/image";
import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/button/style";
import _Button from "vant/es/button";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/picker/style";
import _Picker from "vant/es/picker";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "core-js/modules/es.function.name.js";
/*
 * @Date: 2022-09
 * @LastEditors: wfj
 * @LastEditTime: 2023-07
 * @Description:
 */
import Vue from 'vue';
import App from '@/App.vue';
import '@/../scripts/version';
import '@/styles/cover-vant.less';
import router from '@/router';
import i18n from '@/i18n';
import '@/utils/date.js';
import '@/utils/track';
import '@/core/directives/index.js';
// 这个库会在 pc 端自动将 mouse 事件转换成对应的 touch 事件，使得 vant 组件能够在 pc 端使用
// 举例：field 的【清空按钮】在pc端失效
import '@vant/touch-emulator';

// sentry 监控sdk
import MateSentry from '@/sentry';
import store from '@/store';
import { ENV_DATA } from '@/utils/globalData.js';
import { handleTrack } from '@/utils';

// 重置vant的toast组件
_Toast.setDefaultOptions('loading', {
  duration: 0,
  forbidClick: true
});
_Toast.allowMultiple();
var isPro = ENV_DATA.isPro;
if (isPro) {
  Vue.use(MateSentry, {
    router: router
  });
}
Vue.config.productionTip = false;
Vue.use(_Picker);
Vue.use(_Field);
Vue.use(_Dialog);
Vue.use(_Popup);
Vue.use(_Button);
Vue.use(_Toast);
Vue.use(_Icon);
Vue.use(_Loading);
Vue.use(_Image);
// Vue.use(VueCompositionAPI)

Vue.config.errorHandler = function (err, vm, info) {
  var name = err.name,
    message = err.message,
    stack = err.stack;
  handleTrack('global_err', {
    name: name,
    message: message,
    stack: stack,
    trigger: 'dcPayFront.config.errorHandler',
    info: info
  });
  console.error(err);
};

// 将 vue 添加到全局，在 util 下会用到
window._myVue = new Vue({
  router: router,
  i18n: i18n,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');